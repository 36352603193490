<template>
  <div>
    <table>
      <tr>
        <td style="padding-left:2rem;">
          <b-img v-bind:src="iconPath" width="40rem"></b-img>
        </td>
        <td>
          <h5 class="text-muted">{{title}}</h5>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <br/>
          <ul>
          <li v-for="(point, index) in dotPoints" :key="index">
            {{point}}
          </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

export default {
  name: 'IndustrySummary',
  props: [
    'title',
    'iconPath',
    'dotPoints'
    ],
};
</script>