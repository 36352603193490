<template>
<div>
    <b-row class="d-flex justify-content-around" align-v="center">
      <!-- If the column size setpoint is changed, it also needs to be changed in GetTextColumnOrder(). -->
      <!-- py-4 (padding in y direction - size 4) was added because if the text was on the right, and if a link appeared at the bottom of the text,
      then it wasn't allowing the link to be clicked. Maybe this is due to another section invisibly flowing over the link. Anyway, vertical padding fixed it. -->
      <b-col 
        cols="10"
        :lg="imagePath !== '' ? '7' : '10'"
        :order="GetTextColumnOrder()"
        class="py-4"
        >
        <h2 :id="title" class="text-muted">{{title}}</h2>
        <p class="lead">
          <small>
          <slot>
          </slot>
          </small>
        </p>
      </b-col>
      <div v-show="imagePath !== ''">
        <b-col
          cols="10"
          lg="5"
          align="center"
        :order="GetImageColumnOrder()"
        class="py-2"
        >
            <b-img v-bind:src="imagePath" width="300rem"></b-img>
        </b-col>
      </div>
    </b-row>
</div>
</template>
<script>

import * as Utils from '../utils.js'

// I've got it dynamically stacking when the viewport goes below lg, which is half a FHD monitor.
// It this point, the images need to change back to default order so they always appear below the section.
// I've got this working smoothly using lg, but if I try and get it to work at md the columns seem to still stack at lg, 
// and the images appear in the wrong place until the viewport falls below md.

export default {
  name: 'MainSection',
  props: [
    'title',
    'textSide',
    'imagePath',
  ],
  data() {
    return {
      largerViewPort: false,
    };
  },
  methods: {
    OnWindowResized() {
      // If this viewport size if changed, it also needs to be changed in the column definitions in the template.
      this.largerViewPort = (Utils.ViewportGreaterThan("lg"));
    },
    GetTextColumnOrder: function() {
      if (this.largerViewPort) {
        if (this.textSide == 'right') {
          return '1';
        }
        return '';
      }
      return '';
    },
    GetImageColumnOrder: function() {
      if (this.largerViewPort) {
        if (this.textSide == 'right') {
          return '';
        }
        return '1';
      }
      return '1';
    },
  },
  computed: {
  },
  created() {
    window.addEventListener("resize", this.OnWindowResized);
    this.OnWindowResized(null);
  },
  destroyed() {
    window.removeEventListener("resize", this.OnWindowResized);
  },
};
</script>
<style>
h2::before { 
  display: block; 
  content: " "; 
  margin-top: -100px; 
  height: 100px; 
  visibility: hidden; 
  pointer-events: none;
}
</style>