<template>
    <b-card>
      <template v-slot:header>
        <div align="center">{{name}}</div>
      </template>
    <b-container>
      <b-row>
        <b-col align="center">
            <h1><big>
              {{emoji}}
            </big>
            </h1>
            <!-- <b-img v-show="iconPath !== ''" v-bind:src="iconPath" width="80rem"></b-img>
            <b-img v-show="iconPath === ''" blank=true blankColor="grey" width="80rem"></b-img> -->
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          {{title}}
        </b-col>
      </b-row>
      <!-- <b-row v-if="email">
        <b-col>
          <b-link variant="secondary" :href="`mailto:${email}`">{{email}}</b-link>
        </b-col>
      </b-row> -->
      <br/>
      <b-row>
        <b-col>
          <ul>
          <li v-for="(point, index) in dotPoints" :key="index">
            {{point}}
          </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    </b-card>
</template>
<script>

export default {
  name: 'TeamMember',
  props: [
    'name',
    'title',
    'email',
    'emoji',
    'dotPoints'
  ],
};
</script>