<template>
  <div>

    <br/>
    
    <b-container>

    <MainSection
      title="In-House Manufacturing Capability"
      textSide="left"
      imagePath=""
      >
        <br/>
        ASE has a complete in-house product assembly process which includes:
        <ul>
          <li><a href="#SMT and THT">Surface-mount and Through-hole PCB production</a></li>
          <li><a href="#Quality Control">Automatic Optical Inspection</a></li>
          <li><a href="#Testing and Calibration">Automated and Assisted testing of sub-assemblies</a></li>
          <li><a href="#Quality Control">Strict Quality Control procedures</a></li>
          <li><a href="#Cable Assemblies">Wire and Cable harness processing</a></li>
          <li><a href="#Mechanical Assemblies">Assembly of sub-assemblies in Enclosures</a></li>
          <li><a href="#Full Product Packaging">Final Packaging</a></li>
        </ul>
        <p>
          We offer flexible and high-quality niche manufacturing, specialising in small and medium production runs.
          We can build a small prototype run or one-off batch, right up to regularly scheduled batches.
        </p>
        <p>
          Since we have an in-house engineering team, we can provide feedback to your design team to help improve your products manufacturability,
          or we can make any required changes ourselves.
          We can also design any software or hardware tools required to manufacture your product.
        </p>
    </MainSection>

    <blurb>
      Our <b>supply chain</b> has been built up over 40 years so that you can <b>benefit</b> from our extensive list of contacts and suppliers for <b>component sourcing</b>.
    </blurb>

    <hr/>

    <MainSection
      title="SMT and THT"
      textSide="left"
      :imagePath="require('../assets/production3.jpg')"
      >
      Our SMT production line uses a Juki pick-and-place machine, along with Vapour Phase Reflow technology, to ensure that PCB's are soldered reliably and with minimal thermal shock.
      <br/><br/>
      Our THT production line uses an Inertec selective-soldering machine to reduce labour intensive hand soldering tasks and improve repeatability.
      Our Inertec is able to process both single and double sided PCB's, and provides an inert atmosphere in order to solder joints to the highest possible quality.
      <br/><br/>
      We are able to process fine pitch BGAs and QFPs, double sided PCB's, and component sizes down to 0402. We can also process both leaded and lead-free RoHS assemblies.
      <br/><br/>
      We have experience with conformal coating of PCB's, and can offer this service at an extra cost if required.
    </MainSection>

    <hr/>

    <MainSection
      title="Testing and Calibration"
      textSide="right"
      :imagePath="require('../assets/production1.jpg')"
      >
      We have a range of high-end test and measure equipment capable of performing a variety of calibration, programming and PCB testing tasks as well as complete system testing.
      <br/><br/>
      We can also create custom test jigs and test software to automate the testing of your assemblies to ensure efficiency, reliability and repeatability.
    </MainSection>

    <hr/>

    <MainSection
      title="Quality Control"
      textSide="left"
      :imagePath="require('../assets/production2.jpg')"
      >
      Our highly skilled and trained staff are dedicated to achieving quality in every step of the manufacturing process.
      <br/><br/>
      ASE's Quality Management System (QMS) is the heart of our Quality Policy, and our policies and procedures have been written to comply with ISO9001:2016.
      <br/><br/>
      We use both Automatic Optical Inspection (AOI) as well as manual inspection using digital viewers to ensure 
      we meet IPC-A-610 Acceptability of Electronic Assemblies. This also helps us ensure that any defects or issues are
      detected early in a production run.
      <br/><br/>
      Our Kolb PSB600 PCB cleaning system can be used on mission critical PCB's to ensure that solder balls and flux residue is safely and efficiently removed.
    </MainSection>

    <hr/>

    <MainSection
      title="Cable Assemblies"
      textSide="left"
      imagePath=""
      >
      We have in-house cable processing equipment to manufacture custom cable and wiring assemblies, including the capability to crimp a variety of terminals.
      <br/><br/>
      We have the capability to process multi-core cables, ribbon cables, wire harnesses and many more.
    </MainSection>

    <hr/>

    <MainSection
      title="Mechanical Assemblies"
      textSide="right"
      :imagePath="require('../assets/mechanical1.jpg')"
      >
      Our workshop is equipped with a range of drills, mills and pressing tools that can be used to customise enclosures and other mechanical assemblies.
      <br/><br/>
      We can either work with your custom enclosure, or we can source an enclosure to suit your purpose.
    </MainSection>

    <hr/>

    <MainSection
      title="Full Product Packaging"
      textSide="left"
      :imagePath="require('../assets/cartons1.jpg')"
      >
      All-Systems Electronics can provide a range of packaging options to ensure your electronic product is
      safely and securely packaged ready for distribution.
      <br/><br/>
      We can also affix custom labels and overlays as necessary.
    </MainSection>

    <blurb>
      All-System Electronics provides full <b>turnkey</b> product assembly by utilising a range of <b>technological solutions</b> that can
      help you <b>manufacture</b>, assemble and <b>package</b> your product.
    </blurb>

    </b-container>
    <br/>
  </div>
</template>

<script>
import MainSection from './MainSection.vue';
import Blurb from './Blurb.vue';

export default {
  name: 'Manufacturing',
  components: {
    MainSection,
    Blurb
  },
}
</script>
