<template>
    <div>
      <!--  Add padding so that when the scroll bar disappears when moving between pages the navbar doesn't shift.
            The +5vw is so that the menu isn't butted up against the left side of the frame when viewed on a mobile device with no scroll bar. -->
  <b-navbar toggleable="md" type="light" variant="light" style="padding-left: calc(100vw - 100% + 5vw);" fixed="top">
  <!-- <b-navbar toggleable="lg" type="light" style="padding-left: calc(100vw - 100% + 5vw);"> -->
    <b-container>
      <b-navbar-brand :to="{ name: 'Home' }" @click="IconClicked()"><img src="../assets/ASE_Logo.png" width="56rem"></b-navbar-brand>

      <!-- Right aligned nav items -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav v-model="navbarCollapseVisible">
      <b-navbar-nav class="ml-auto" right style="margin-right: 0%;">
        <b-nav-item :to="{ name: 'Home' }">Home</b-nav-item>
        <b-nav-item :to="{ name: 'Design' }">Design</b-nav-item>
        <b-nav-item :to="{ name: 'Manufacturing' }">Manufacturing</b-nav-item>
        <b-nav-item :to="{ name: 'Careers' }">Careers</b-nav-item>
        <b-nav-item-dropdown text="Support" right>
          <b-dropdown-item :to="{ name: 'SupportAlcoMeasure' }">AlcoMeasure</b-dropdown-item>
          <!-- <b-dropdown-item>HZD Multegas System</b-dropdown-item> -->
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" right style="margin-right: 10%;">
        <b-nav-item :to="{ name: 'Contacts' }">About & Contact</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    </b-container>
  </b-navbar>
</div>
</template>
<script>

export default {
  name: 'Navbar',
  data() {
    return {
      navbarCollapseVisible: false,
    }
  },
  methods: {
    IconClicked() {
      this.navbarCollapseVisible = false;
    },
  },
};
</script>
<style>
/* .navbar-brand span {
	font-size:1.4rem;
	line-height:2.5;
	font-weight: 200;
	display:inline-block;
	vertical-align:bottom;
	padding: 0 0 0 10px;
} */

hr.ASE_Navbar {
  margin: 3px auto;
  border-top: 1px solid #BB3E53;
}

nav a {
/* color: #ffffff !important; */
  /* color: var(--dark) !important; */
}
</style>