<template>
  <div>
    <b-container>

      <br/>

      <MainSection
        title="Feedback"
        textSide="left"
        imagePath=""
      >
        <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">

          <b-form-group
            id="input-group-name"
            label="Your Name:"
            label-for="input-name">
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Enter name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-email"
            label="Your email address:"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-services"
            label="Services provided by ASE:"
            label-for="checkboxes-services"
            v-slot="{ ariaDescribedby }"
            >
            <b-form-checkbox-group
              v-model="form.services"
              id="checkboxes-services"
              :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox value="PCB Assembly">PCB Assembly</b-form-checkbox>
              <b-form-checkbox value="PCB Design">PCB Design</b-form-checkbox>
              <b-form-checkbox value="Software Design">Software Design</b-form-checkbox>
              <b-form-checkbox value="Other">Other</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group
            id="input-group-rating"
            label="Satisfaction:"
            label-for="input-rating">
            <b-form-select
              id="input-rating"
              v-model="form.satisfaction"
              :options="satisfied"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-comments"
            label="Comments:"
            label-for="input-comments">
            <b-form-textarea
              id="input-comments"
              v-model="form.comments"
              placeholder="Enter comments"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-button type="submit" variant="primary">Submit</b-button>  &nbsp;&nbsp;&nbsp;&nbsp; 
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>
      </MainSection>

      <b-modal ref="modal-failed" title="Failed" ok-only="true" @hidden="onModalClose">
          Unfortunately your feedback was unable to be sent.<br /><br />

          Please email your feedback to <b-link variant="secondary" href="mailto:feedback@all-systems.com.au">feedback@all-systems.com.au</b-link>.<br />
          Please leave us a <b-link href="https://g.page/r/CfRMQIOagF1REBM/review" target="_blank">Google review</b-link>.
      </b-modal>

      <b-modal ref="modal-success" title="Success" ok-only="true" @hidden="onModalClose">
          Thank you for sending us your feedback, we look forward to working with you again in the future.
      </b-modal>

      <b-modal ref="modal-satisfied" title="Success" ok-only="true" @hidden="onModalClose">
          Thank you for sending us your feedback, we look forward to working with you again in the future.<br /><br />
          Please leave us a <b-link href="https://g.page/r/CfRMQIOagF1REBM/review" target="_blank">Google review</b-link>.
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import MainSection from './MainSection.vue';
import axios from 'axios';

export default {
  components: {
    MainSection
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        satisfaction: null,
        services: [],
        comments: ''
      },
      satisfied: [{ text: 'Select One', value: null }, 'Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
      show: true
    }
  },
  methods: {
    onSubmit(event) {
      axios
      .post('php/sendFeedback.php', JSON.stringify(this.form))
      .then(() => {
        if ((this.form.satisfaction == 'Very Satisfied') ||
          (this.form.satisfaction == 'Satisfied')) {
          this.$refs['modal-satisfied'].show()
        }
        else {
          this.$refs['modal-success'].show()
        }
      })
      .catch((/*error*/) => {
        this.$refs['modal-failed'].show()
      })
            
      event.preventDefault();
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.satisfaction = null
      this.form.services = []
      this.form.comments = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onModalClose() {
      window.location.replace("/");
    }
  }
}
</script>