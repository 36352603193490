<template>
<div>
    <b-row class="justify-content-center">
      <b-col cols="10" md="8">
      <b-form-text text-variant="secondary">
        <h5 style="text-align: center;">
        <slot>
        </slot>
        </h5>
      </b-form-text>
      </b-col>
    </b-row>
</div>
</template>
<script>

export default {
  name: 'Blurb',
};
</script>