<template>
  <div>
    <b-container>

    <br/>

    <MainSection
      title="Careers at All-Systems Electronics"
      textSide="left"
      imagePath=""
      >
        All-Systems Electronics are a small but diverse team that covers the following areas:
        <ul>
          <li>Electronics Manufacture</li>
          <li>Electronics Design</li>
          <li>Administration</li>
        </ul>
        <p>
          While we may not be actively hiring in all areas, if you think you would be a good fit for our company please get in touch.
          <br/>
          See below for active job openings.
        <br/><br/>
          Send your job enquiry and resume to: <b-link variant="secondary" href="mailto:jobs@all-systems.com.au">jobs@all-systems.com.au</b-link>.
        </p>
    </MainSection>

  <JobOpening
    class="h-100"
    title="Trainee Engineer (Junior)"
    imagePath=""
  >
    Full-Time 6 month Internship, Monday to Friday 8am-4:30pm<br/>
    <br/>
    The successful candidate will be exposed to the overall engineering environment at ASE, focusing on developing their technical knowledge, practical capabilities and general work skills;
    all within the framework of a quality-controlled workplace.<br/>
    <br/>
    Must be an Australian Citizen or have Permanent Residency rights.<br/>
    <br/>
    The objective of ASE's internship program is to provide future engineers an opportunity to gain practical skills, technical knowledge and first hand insight into the electronic design and manufacturer industry.
    Understanding how things are made and how they can fail is a fundamental skill that has benefited 30 years of Engineers that have gone through ASE's internship program.<br/>
    <br/>
    ASE is a close-knit workplace where several full-time employees were former interns and together have created an inclusive and dynamic workplace where everyone's participation and contribution is valued.<br/>
    <br/>
    Some of the specifics of the internship program we offer includes:
    <ul>
      <li>Comprehensive induction program.</li>
      <li>Extensive hand soldering training (IPC-A-610).</li>
      <li>Learning how to use a number of hand tools and machinery in a safe manner.</li>
      <li>Understanding what is involved in a quality environment (ISO9001) and how you contribute.</li>
      <li>Learning about how various products for different industries are made.</li>
      <li>Undertaking a range of testing and calibration across different projects.</li>
      <li>Troubleshooting and repair of faulty circuits using schematics (IPC-7711/7721).</li>
      <li>Appreciation of Design For Manufacturability principles.</li>
      <li>Collaborate and communicate with others in a professional environment.</li>
      <li>Opportunity to undertake a small in-house project to improve production process.</li>
    </ul>
    <br/>
    <h3>Key Responsibilities</h3>
      <ul>
        <li>Printed circuit board assembly, inspection and testing.</li>
        <li>Operating a wide range of electronic assembly equipment.</li>
        <li>Proactively contribute to ongoing personal and team development.</li>
        <li>Surface-mount and Through-hole Soldering.</li>
        <li>Cable and mechanical assembly.</li>
        <li>Troubleshooting and fault finding.</li>
        <li>Adhere to All-Systems' Quality Management System, WHS regulations, and other policies, and procedures.</li>
      </ul>

      <h3>Required Skills and Experience</h3>
        <ul>
          <li>Passionate about electronics and/or technology.</li>
          <li>Excellent written and verbal communication skills.</li>
          <li>Ability to learn and master hand tools like soldering irons, drills, screwdrivers.</li>
          <li>A strong desire to learn.</li>
          <li>Fully committed and dedicated.</li>
          <li>Ability to work in a small team environment.</li>
          <li>Strong attention to detail.</li>
          <li>Good personal presentation.</li>
        </ul>
        <h3>How to apply</h3>
        Applications are accepted twice a year:<br/>
        <ul>
          <li>1st intake: August to October (for mid-January start)</li>
          <li>2nd intake: February to April (for mid-June start)</li>
        </ul>
        Applications need to be sent during the intake period to be considered.<br/>
        <br/>
        Before submitting application combine your Cover Letter, CV and Academic Transcript into ONE pdf document.<br/>
        Label the pdf file in the following format: LASTNAME_FirstNames.pdf.<br/>
        eg. SMITH_JohnMichael.pdf.<br/>
        <br/>
        Please do not call to follow up application. All applicants will be acknowledged by email that their application has been received, and only those shortlisted will be contacted thereafter.
    </JobOpening>

    <JobOpening
      class="h-100"
      title="Electronics Solderer & Assembler"
      imagePath=""
    >
      We are seeking an electronics assembler with excellent SMT and THT hand soldering skills. The successful candidate will have at least 2 years experience in electronics production or servicing, with an eye for high-quality and the ability to closely follow instructions.<br/>
      <br/>
      The role will initially be casual, with the possible future option of permanency.<br/>
      <br/>
      Must be an Australian Citizen or have Permanent Residency rights.<br/>
      <br/>
      <h3>Key Responsibilities</h3>
        <ul>
          <li>
            Assembly and rework of PCBs.
          </li>
          <li>
            Inspection and testing of PCBs.
          </li>
          <li>
            Adhere to All-Systems' Quality Management System, WHS regulations, and other policies, and procedures.
          </li>
        </ul>

        <h3>Required Skills and Experience</h3>
          <ul>
            <li>
              Minimum 2 years’ experience in an electronics production or servicing/repair role.
            </li>
            <li>
              Advanced hand-soldering and rework skills, particularly with SMT components.
            </li>
            <li>
              Ability to closely follow assembly and test procedures.
            </li>
            <li>
              Ability to uphold a high standard of quality.
            </li>
          </ul>

        <h3>Desirable Skills and Experience</h3>
          <ul>
            <li>
              Experience with PCB manufacturing.
            </li>
            <li>
              Cert 3 in Electronics & Communication, IPC training, or other related study.
            </li>
            <li>
              Experience operating workshop machinery, such as a mill, drill press, and hand tools.
            </li>
          </ul>
      </JobOpening>

    <br/>
    </b-container>

  </div>
</template>

<script>
import MainSection from './MainSection.vue';
import JobOpening from './JobOpening.vue';

export default {
  name: 'Design',
  components: {
    MainSection,
    JobOpening
  },
}
</script>