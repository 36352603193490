<template>
  <div>
    <b-container>
    <br/>
    <MainSection
      title="AlcoMeasure WM1 Support"
      imagePath=""
      >
      <br/>
    <b-row>
      <b-col align="center" cols="7">
      <b-img v-bind:src="require('../assets/CaseFrontRunning.png')" width="250rem"></b-img>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
      <br/>
    <b-row>
      <b-col>
      <p>
      Firmware, PC Software, and documentation can be found on the <b-link href="https://github.com/All-Systems-Electronics/AlcoMeasure" target="_blank">AlcoMeasure GitHub Repository</b-link>.</p>
      <p>
      For instructions on how to operate the AlcoMeasure WM1 or AlcoMeasure Utility, including updating firmware, see the <b-link href="https://github.com/All-Systems-Electronics/AlcoMeasure/tree/master/documentation" target="_blank">AlcoMeasure WM1 User Manual</b-link>
      </p>
      <p>
      For all sales, service and technical assistance, contact <b-link href="https://breathalyser.com.au/contact" target="_blank">Breathalyser Sales & Service</b-link>.
      </p>
      </b-col>
    </b-row>
    </MainSection>
    </b-container>

  </div>
</template>

<script>
import MainSection from './MainSection.vue';

export default {
  name: 'SupportAlcoMeasure',
  components: {
    MainSection,
  },
  data() {
    return {
    }
  },
}
</script>