<template>
  <div>
    <b-container>

    <br/>
    
    <b-row>
      <b-col lg class="d-flex align-items-stretch mb-3">
          <b-card header="Contact Us" style="width:100%;">
            <b-card-text>
              Get in touch to discuss your next project
            </b-card-text>
            
            <b-table-simple borderless small>
              <b-tbody>
              <b-tr>
                <b-td>Phone</b-td><b-td><b-link variant="secondary" href="tel:+61-2-9624-4644">+61 2 9624 4644</b-link></b-td>
              </b-tr>
              <b-tr>
                <b-td>Sales Enquiries</b-td><b-td><b-link variant="secondary" href="mailto:sales@all-systems.com.au">sales@all-systems.com.au</b-link></b-td>
              </b-tr>
              <b-tr>
                <b-td>Job Enquiries</b-td><b-td><router-link :to="{ name: 'Careers' }">Careers</router-link></b-td>
              </b-tr>
              <b-tr>
                <b-td>Postal Address</b-td>
                <b-td>
                  16 Hope Street<br />
                  Seven Hills, NSW, 2147<br />
                  Australia<br />
                  </b-td>
              </b-tr>
              <b-tr>
                <b-td>ABN</b-td>
                <b-td>
                  ALL-SYSTEMS ELECTRONICS PTY LTD<br />
                  31 002 182 827<br />
                  </b-td>
              </b-tr>
              <b-tr>
                <b-td></b-td>
              </b-tr>
              <b-tr>
                <b-td><b-link href="https://github.com/All-Systems-Electronics" target="_blank"><b-img :src="require('../assets/GitHub_Logo.png')" height="33rem"></b-img></b-link></b-td><b-td><b-link href="https://linkedin.com/company/all-systems-electronics" target="_blank"><b-img :src="require('../assets/LI-Logo_Small.png')" height="30rem"></b-img></b-link></b-td>
              </b-tr>
             </b-tbody>
           </b-table-simple>
          </b-card>
      </b-col>
      <b-col lg class="b-col d-flex align-items-stretch mb-3">
          <b-card header="Find Us" style="width:100%;">
            <b-card-text>
              Schedule a visit at <b-link variant="secondary" href="https://www.google.com.au/maps/place/All-Systems+Electronics/@-33.7702338,150.9367651,19.08z/data=!4m6!3m5!1s0x6b12988e776ae499:0x515d809a83404cf4!8m2!3d-33.7702066!4d150.9369759!16s%2Fg%2F1tg7s4_7?entry=ttu" target="_blank">16 Hope St, Seven Hills, 2147</b-link>
            </b-card-text>
            <div id="map-container-google-8" class="z-depth-1-half map-container-5">
              <a href="https://www.google.com.au/maps/place/All-Systems+Electronics/@-33.7702338,150.9367651,19.08z/data=!4m6!3m5!1s0x6b12988e776ae499:0x515d809a83404cf4!8m2!3d-33.7702066!4d150.9369759!16s%2Fg%2F1tg7s4_7?entry=ttu" target="_blank">
                <b-img :src="require('../assets/aseMap.jpg')"
                frameborder="0" style="border:0" fluid></b-img>
              </a>
        </div>
          </b-card>
      </b-col>
    </b-row>
    </b-container>

    <br/>

    <b-container>
      <blurb>
        The mission of <b>All-Systems Electronics</b> is to provide custom-designed electronics and software
        solutions, specialising in <b>embedded systems</b>, using the very latest advances and techniques.
        All-Systems Electronics contributes to the <b>Australian</b> manufacturing industry by focusing on 
        <b>in-house manufacturing</b> capabilities in order to ensure a <b>high-quality</b> end product.
      </blurb>
    </b-container>

    <br/>
    
    <b-container>
    <b-row class="justify-content-center">
      <b-col cols="auto" class="mb-3">
        <h2 class="text-muted">Leadership Team</h2>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" sm="6" md="6" lg="5" class="mb-3">
        <TeamMember
          class="h-100"
          name="Graham Cardwell"
          title="Managing Director"
          email="gjc@all-systems.com.au"
          emoji="👨‍💼🎬"
          :dotPoints="[
            'Business Direction',
            'Schematic Design',
            'Mechanical Design',
          ]"
        ></TeamMember>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="5" class="mb-3">
        <TeamMember
          class="h-100"
          name="Darren Pearce"
          title="Senior Software Engineer"
          email="darren@all-systems.com.au"
          emoji="👨‍💻"
          :dotPoints="[
            'Software Development',
            'Product Design',
            'Project Management',
          ]"
        ></TeamMember>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" sm="6" md="6" lg="5" class="mb-3">
        <TeamMember
          class="h-100"
          name="Kevin Formosa"
          title="Production Manager"
          email="kevin@all-systems.com.au"
          emoji="👨🛠️"
          :dotPoints="[
            'Production Scheduling',
            'Purchasing and Kitting',
            'Quality Management'
          ]"
        ></TeamMember>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="5" class="mb-3">
        <TeamMember
          class="h-100"
          name="Angas Torrance"
          title="Lead Design Engineer"
          email="angas@all-systems.com.au"
          emoji="👨📐"
          :dotPoints="[
            'PCB Design and Layout',
            'Product Design',
            'Production Engineering',
          ]"
        ></TeamMember>
      </b-col>
    </b-row>
    </b-container>
    <br />

    <b-container>
    <b-row class="justify-content-center">
      <b-col cols="2" class="mb-3">
        <h2 class="text-muted">Milestones</h2>
      </b-col>
    </b-row>
    <!-- <h2 class="text-muted">Milestones</h2> -->
    <dl>
      <dt>1981</dt><dd>All-Systems Electronics Pty Ltd incorporated, first custom electronic project designed and manufactured.</dd>
      <dt>1982</dt><dd>Second-hand Wave-Soldering Machine installed and commissioned.</dd>
      <dt>1983</dt><dd>Built custom computer based on a 6809 microprocessor to be used for managing office-work, and firmware development using a Pascal compiler.</dd>
      <dt>1985</dt><dd>Established Factory in Seven Hills.</dd>
      <dt>1987</dt><dd>First IBM Compatible PC, an NEC PowerMate running MSDOS.</dd>
      <dt>1987</dt><dd>Last hand-taped PCB artwork, first CAD PCB artwork using Protel.</dd>
      <dt>1988</dt><dd>Designed an Outdoor Lighting Control System for World Expo '88 in Brisbane.</dd>
      <dt>1989</dt><dd>First SMT Printed Circuit Board design.</dd>
      <dt>1990</dt><dd>Designed first Multegas Alarm Panel.</dd>
      <dt>1992</dt><dd>Breathalyser first certified to AS3547.</dd>
      <dt>1995</dt><dd>Connected to the Internet.</dd>
      <dt>1996</dt><dd>Started using MSP430F family of 16-bit microcontrollers.</dd>
      <dt>2001</dt><dd>Started development of Aeration Controller for grain storage silos.</dd>
      <dt>2004</dt><dd>Released 6-channel Aeration Controller.</dd>
      <dt>2005</dt><dd>Replaced old Wave-Soldering Machine with new ATF 20/33 Wave-Soldering Machine.</dd>
      <dt>2006</dt><dd>Commenced in-house SMT production with the purchase of a Fritsch Model 600 Pick & Place Machine.</dd>
      <dt>2009</dt><dd>Implemented internal Wiki for work instructions and staff communication.</dd>
      <dt>2012</dt><dd>First design using an ARM 32-bit microcontroller.</dd>
      <dt>2013</dt><dd>AlcoMeasure WM1 breathalyser certified to AS3547.</dd>
      <dt>2014</dt><dd>Replaced Fritsch Pick & Place Machine with new Juki KE-1080L Pick & Place Machine.</dd>
      <dt>2019</dt><dd>Replaced ATF Wave Soldering Machine with an Inertec Cube 460 Selective Soldering Machine, Lead-Free.</dd>
      <dt>2020</dt><dd>Designed new website, sold 1000th AlcoMeasure WM1, installed Kolb PSB600 PCB cleaning system.</dd>
      <dt>2022</dt><dd>Installed ASSCON VP1000 Vapor Phase Reflow Oven, started using iMXRT range of crossover ARM microcontrollers.</dd>
      <dt>2023</dt><dd>Achieved ISO 9001 Certification, AlcoMeasure WM1 recertified to latest AS3547, installed 3D printer.</dd>
      <dt>2024</dt><dd>Installed PCB Dryer.</dd>
    </dl>
    </b-container>

  </div>
</template>

<script>
import TeamMember from './TeamMember.vue';
import Blurb from './Blurb.vue';

export default {
  name: 'Contacts', //this is the name of the component
  components: {
    TeamMember,
    Blurb,
  },
}
</script>
<style>
</style>