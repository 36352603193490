<template>
  <div>
    <b-container>

    <br/>

    <MainSection
      title="Overall Product Design"
      textSide="left"
      imagePath=""
      >
        All-Systems Electronics are experts at all stages of the product design lifecycle:
        <ul>
          <li>Design Specification</li>
          <li>Circuit testing and breadboarding</li>
          <li>Hardware and software design</li>
          <li>Prototyping and User Acceptance Testing</li>
          <li>End-user software applications</li>
          <li>Full-scale production</li>
        </ul>
        <p>
          We take our comprehensive knowledge of manufacturing gained from running our in-house production line
          and apply it to your product design to ensure it is Designed for Manufacture and Assembly.
          We can also lean on our production capabilities, including our workshop and 3D printing capabilities, to quickly and
          reliably prototype new technologies and designs.
        </p>
    </MainSection>

    <blurb>
      You can build on our <b>40 years</b> of experience in <b>electronic product design</b> to make sure you end up with the <b>product you want</b>.
      From small PCB <b>prototypes</b> or <b>software only</b> projects, right up to
      <b>complete manufacturable products</b>, ASE are capable of meeting your <b>hardware and software</b> design needs.
    </blurb>

    <hr/>

    <MainSection
      title="Hardware Design"
      textSide="left"
      :imagePath="require('../assets/PCB1.jpg')"
      >
        Whether you are starting with an idea, a hand-sketched drawing, a full schematic or a proof of concept, our engineering team can join in at any stage and help you fast track your concept through to a finished product, ready for manufacture.<br/>
        <br/>
        Some of the key areas our hardware design team can help you with are:
        <ul>
          <li>Product Requirements Review</li>
          <li>Mixed signal, multilayer board-level design</li>
          <li>System Design</li>
          <li>Schematic capture and layout</li>
          <li>Prototype and Proof-of-Concept</li>
          <li>Design for EMC and safety standards </li>
          <li>Low-power design</li>
          <li>Low-cost optimisation and Design for Manufacture</li>
          <li>Battery/power management design</li>
          <li>Electromechanical integration & Test Strategy</li>
        </ul>
        Once your design is ready, we can offer full support through to sub-contract manufacturing, giving you full control over your product in one place.
    </MainSection>

    <hr/>

    <MainSection
      title="Software Design"
      textSide="right"
      :imagePath="require('../assets/Code1.png')"
      >
        ASE have considerable experience developing the full software stack, and can work closely with you through all stages of development, liaising with external teams as required.
        <br /><br />
        We use Subversion version control internally, and GitHub for projects that require external collaboration, although we are flexible and can use whatever tools you require.
        <br /><br />
        We are able to quickly bring up software simulations of functionality to help you make design decisions before hardware is manufactured.
        <br /><br />
        We have experience in the following languages and technologies:
        <ul>
          <li>Embedded firmware in C/C++, either Bare-Metal or with an RTOS.</li>
          <li>Embedded stacks for USB, TCP/IP, SSL/TLS.</li>
          <li>Windows/Linux software with C/C++, Qt, C#, Python and Delphi.</li>
          <li>Cloud technologies using HTML5/JS, Vue.js, PHP, and Azure.</li>
          <li>Databases, such as SQLite, MS SQL Server, MySQL.</li>
          <li>Single-board systems like Arduino, Raspberry Pi, and Tinkerboard.</li>
        </ul>
    </MainSection>

    <hr/>

    <MainSection
      title="Intellectual Property"
      textSide="left"
      imagePath=""
      >
        All Intellectual Property (IP) paid for by you during the project will be assigned to you upon final payment,
        including documentation such as source code, schematics and PCB layouts. Any inclusion of 3rd-party libraries will be discussed with you to ensure that the relevant licences are agreeable.

    </MainSection>

    <blurb>
      <b>Protect</b> your IP by <b>partnering</b> with a <b>local</b> Designer and Manufacturer.
    </blurb>

    <br/>
    </b-container>

  </div>
</template>

<script>
import MainSection from './MainSection.vue';
import Blurb from './Blurb.vue';

export default {
  name: 'Design',
  components: {
    MainSection,
    Blurb
  },
}
</script>